import React, { ReactNode } from "react";
import lacroix from "../images/lacroix.jpg";
import styled from "styled-components";
import { Layout } from "../components/layout";

/*
 * Constants.
 */

const data: { link: string; label: string; content: ReactNode }[] = [
  {
    link: "https://www.thisamericanlife.org/498/the-one-thing-youre-not-supposed-to-do/act-three-0",
    label:
      "This American Life Episode 498: The One Thing You're Not Supposed To Do | Act 3",
    content: (
      <>
        "Out of the Woods" by Ben Loory. One of my favorite short stories, about
        a man and a moose.
      </>
    ),
  },
  {
    link: "https://www.nytimes.com/2007/01/18/garden/18roomies.html",
    label: "Taking Power, Sharing Cereal",
    content: (
      <>
        Just a random story about some of the most powerful people in the US
        living together like college students.
      </>
    ),
  },
  {
    link: "http://www.livingroomcandidate.org/commercials/1952",
    label: "The Living Room Candidate",
    content: (
      <>
        This website features presidential advertising campaigns going all the
        way back to 1952. I just generally love the history of the US presidency
        and the presidents themselves, it's a fascinating office that has been
        filled by some fascinating men. My mom (shout out to kimshibsss) and I
        are currently on a mission to visit every presidential library in the US
        (7 down,{" "}
        <a href="https://en.wikipedia.org/wiki/Presidential_library#List_of_presidential_libraries">
          {" "}
          a bunch
        </a>{" "}
        to go!)
      </>
    ),
  },
  {
    link: "https://www.newyorker.com/humor/daily-shouts/dont-even-think-about-talking-to-me-until-ive-had-my-second-la-croix",
    label:
      "Don’t Even Think About Talking to Me Until I’ve Had My Second La Croix",
    content: (
      <>
        When I was interning at Amazon in fall 2017 I constructed{" "}
        <a href={lacroix}>a shrine</a> on my desk. (Note from 2022 Trevor: This
        seems hilariously dated now but keeping it around as a fun relic of what
        2017 me was like)
      </>
    ),
  },
  {
    link: "https://www.nytimes.com/2016/09/04/opinion/sunday/how-to-tell-a-mother-her-child-is-dead.html",
    label: "How to Tell a Mother Her Child Is Dead",
    content: (
      <>
        Sobering essay from an emergency room doctor in Philadelphia. If
        anything on this list is going to make you cry it's this one.
      </>
    ),
  },
  {
    link: "https://www.smbc-comics.com/index.php?id=3749",
    label: "SMBC Comic: 5 Paragraph Essay",
    content: <>A lighter way to finish of the list after that last essay.</>,
  },
];

/*
 * Style.
 */

const StyledLink = styled.li`
  margin-bottom: 25px;
`;

const StyledDescription = styled.ul`
  margin-top: 5px;
`;

/*
 * Page.
 */

const LinksPage = () => (
  <Layout>
    <h2>Links I like</h2>
    <p>
      A variety of articles/websites/comics I've had saved in my bookmarks
      forever. I figured this is a better place to store them.
    </p>
    <ul>
      {data.map(({ link, label, content }) => (
        <StyledLink>
          <a href={link}>{label}</a>
          <StyledDescription>
            <li>{content}</li>
          </StyledDescription>
        </StyledLink>
      ))}
    </ul>
  </Layout>
);

export default LinksPage;
